import lodash, { isEmpty } from 'lodash';
import { IntlShape } from 'react-intl/src/types';
import { neverReachedFor } from '../../../../../utils';
import { MeansOfTransportMode } from '../../../domain/meansOfTransport.types';
import { AdditionalProductId, Shipment } from '../../../reducers/shipments/types';
import { FieldErrorType } from '../../common/form/types';
import {
    AdditionalProductIdErrors,
    AdditionalProductIdFieldError,
    MeansOfTransportFieldError,
    ShipmentEditErrors,
    TermsOfDeliveryErrors,
} from './types';

export const minValueInputQuantity = 1;
export const maxValueInputQuantity = 9_999_999_999;

export const minValueEditableNumberField = 1;
export const maxValueEditableNumberField = 99_999_999;
export const maxValueLabelNumberField = 999_999_999;

export const SOFTWARE_STATUS_PATTERN = /^(?!0000)([0-9A-Z]{4})$/;
export const HARDWARE_STATUS_PATTERN = /^([HXYZ](?!00)[0-9]{2})|((?!000)[0-9]{3})$/;
export const PARTS_GENERATION_STATUS_PATTERN = /^(00S|(?!00)[0-9]{2}[HKS])$/;
export const TRANSPORT_ORDER_NUMBER_PATTERN = /^[a-zA-Z0-9-]+$/;

// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: Legacy code, field validation rules
const validateMeansOfTransport = (meansOfTransport: Shipment['meansOfTransport']) => {
    const meansOfTransportErrors: ShipmentEditErrors['meansOfTransport'] = {};

    if (!meansOfTransport) {
        return meansOfTransportErrors;
    }

    const mode = meansOfTransport.mode;
    switch (mode) {
        case MeansOfTransportMode.MARITIME:
            if (meansOfTransport.shipName && meansOfTransport.shipName === meansOfTransport.containerNumber) {
                meansOfTransportErrors.shipName = MeansOfTransportFieldError.SAME_VALUE_IN_MULTIPLE_FIELDS;
                meansOfTransportErrors.containerNumber = MeansOfTransportFieldError.SAME_VALUE_IN_MULTIPLE_FIELDS;
            }
            if (meansOfTransport.shipName && meansOfTransport.shipName.length > 25) {
                meansOfTransportErrors.shipName = FieldErrorType.TOO_LONG;
            }
            if (meansOfTransport.containerNumber && meansOfTransport.containerNumber.length > 12) {
                meansOfTransportErrors.containerNumber = FieldErrorType.TOO_LONG;
            }
            if (meansOfTransport.shipName && meansOfTransport.shipName.trim() === '') {
                meansOfTransportErrors.shipName = FieldErrorType.BLANK;
            }
            if (meansOfTransport.containerNumber && meansOfTransport.containerNumber.trim() === '') {
                meansOfTransportErrors.containerNumber = FieldErrorType.BLANK;
            }
            break;
        case MeansOfTransportMode.RAIL:
            if (meansOfTransport.trainNumber && meansOfTransport.trainNumber.length > 25) {
                meansOfTransportErrors.trainNumber = FieldErrorType.TOO_LONG;
            }
            if (meansOfTransport.railCarNumber && meansOfTransport.railCarNumber.length > 12) {
                meansOfTransportErrors.railCarNumber = FieldErrorType.TOO_LONG;
            }
            if (meansOfTransport.trainNumber && meansOfTransport.trainNumber.trim() === '') {
                meansOfTransportErrors.trainNumber = FieldErrorType.BLANK;
            }
            if (meansOfTransport.railCarNumber && meansOfTransport.railCarNumber.trim() === '') {
                meansOfTransportErrors.railCarNumber = FieldErrorType.BLANK;
            }
            break;
        case MeansOfTransportMode.ROAD:
            if (meansOfTransport.registrationNumber && meansOfTransport.registrationNumber.length > 25) {
                meansOfTransportErrors.registrationNumber = FieldErrorType.TOO_LONG;
            }
            if (meansOfTransport.trailerRegistrationNumber && meansOfTransport.trailerRegistrationNumber.length > 12) {
                meansOfTransportErrors.trailerRegistrationNumber = FieldErrorType.TOO_LONG;
            }
            if (meansOfTransport.registrationNumber && meansOfTransport.registrationNumber.trim() === '') {
                meansOfTransportErrors.registrationNumber = FieldErrorType.BLANK;
            }
            if (
                meansOfTransport.trailerRegistrationNumber &&
                meansOfTransport.trailerRegistrationNumber.trim() === ''
            ) {
                meansOfTransportErrors.trailerRegistrationNumber = FieldErrorType.BLANK;
            }
            break;
        case MeansOfTransportMode.AIR:
            if (meansOfTransport.flightNumber && meansOfTransport.flightNumber.length > 25) {
                meansOfTransportErrors.flightNumber = FieldErrorType.TOO_LONG;
            }
            if (meansOfTransport.flightNumber && meansOfTransport.flightNumber.trim() === '') {
                meansOfTransportErrors.flightNumber = FieldErrorType.BLANK;
            }
            break;
        case MeansOfTransportMode.MAIL:
            if (meansOfTransport.parcelNumber && meansOfTransport.parcelNumber.length > 25) {
                meansOfTransportErrors.parcelNumber = FieldErrorType.TOO_LONG;
            }
            if (meansOfTransport.parcelNumber && meansOfTransport.parcelNumber.trim() === '') {
                meansOfTransportErrors.parcelNumber = FieldErrorType.BLANK;
            }
            break;
        case MeansOfTransportMode.MULTIMODAL:
            if (
                meansOfTransport.swapBodyRegistrationNumber &&
                meansOfTransport.swapBodyRegistrationNumber.length > 25
            ) {
                meansOfTransportErrors.swapBodyRegistrationNumber = FieldErrorType.TOO_LONG;
            }
            if (meansOfTransport.swapBodyNumber && meansOfTransport.swapBodyNumber.length > 12) {
                meansOfTransportErrors.swapBodyNumber = FieldErrorType.TOO_LONG;
            }
            if (
                meansOfTransport.swapBodyRegistrationNumber &&
                meansOfTransport.swapBodyRegistrationNumber.trim() === ''
            ) {
                meansOfTransportErrors.swapBodyRegistrationNumber = FieldErrorType.BLANK;
            }
            if (meansOfTransport.swapBodyNumber && meansOfTransport.swapBodyNumber.trim() === '') {
                meansOfTransportErrors.swapBodyNumber = FieldErrorType.BLANK;
            }
            break;
        default:
            neverReachedFor(mode);
    }

    return meansOfTransportErrors;
};

const validateCustoms = (customs: Shipment['customs']): ShipmentEditErrors['customs'] => {
    const customsErrors: ShipmentEditErrors['customs'] = {};

    if (!customs) {
        return customsErrors;
    }

    if (customs.value?.amount && customs.value?.amount.toString().length > 35) {
        if (!customsErrors.value) {
            customsErrors.value = {};
        }

        customsErrors.value.amount = FieldErrorType.TOO_LONG;
    }

    if (customs.value?.amount && customs.value?.amount > parseFloat('1.0E+33')) {
        if (!customsErrors.value) {
            customsErrors.value = {};
        }

        customsErrors.value.amount = FieldErrorType.TOO_LONG;
    }
    if (customs.preferenceAuthorization?.text && customs.preferenceAuthorization?.text.length > 5 * 256) {
        customsErrors.preferenceAuthorization = { text: FieldErrorType.TOO_LONG };
    }

    return customsErrors;
};

export const validateShipment = (shipment: Shipment) => {
    const shipmentErrors: ShipmentEditErrors = {};
    const termsOfDeliveryErrors: TermsOfDeliveryErrors = { location: {} };

    if (shipment.load.length === 0 || shipment.load.some((deliveryNote) => deliveryNote.loadItems.length === 0)) {
        shipmentErrors.someDeliveryNotesAreEmpty = true;
    }
    if (shipment.termsOfDelivery?.location?.name && shipment.termsOfDelivery.location.name.length >= 256) {
        termsOfDeliveryErrors.location!.name = FieldErrorType.TOO_LONG;
        shipmentErrors.termsOfDelivery = termsOfDeliveryErrors;
    }
    const customErrors = validateCustoms(shipment.customs);
    if (!isEmpty(customErrors)) {
        shipmentErrors.customs = customErrors;
    }

    if (shipment.transportOrderNumber) {
        if (shipment.transportOrderNumber.length > 34) {
            shipmentErrors.transportOrderNumber = FieldErrorType.TOO_LONG;
        } else if (!TRANSPORT_ORDER_NUMBER_PATTERN.test(shipment.transportOrderNumber)) {
            shipmentErrors.transportOrderNumber = FieldErrorType.CONTAINS_INVALID_CHARACTERS;
        }
    }

    const meansOfTransportErrors = validateMeansOfTransport(shipment.meansOfTransport);
    if (!isEmpty(meansOfTransportErrors)) {
        shipmentErrors.meansOfTransport = meansOfTransportErrors;
    }

    shipment.load.forEach((deliveryNote, deliveryNoteIndex) => {
        deliveryNote.loadItems.forEach((loadItem, loadItemIndex) => {
            const batchNumberError = validateBatchNumber(loadItem.batchNumber);
            if (batchNumberError !== undefined) {
                lodash.set(
                    shipmentErrors,
                    `load[${deliveryNoteIndex}].loadItems[${loadItemIndex}].batchNumber`,
                    batchNumberError,
                );
            }

            if (loadItem.additionalProductId !== undefined) {
                const additionalProductIdErrors = validateAdditionalProductId(loadItem.additionalProductId);
                if (additionalProductIdErrors !== undefined) {
                    lodash.set(
                        shipmentErrors,
                        `load[${deliveryNoteIndex}].loadItems[${loadItemIndex}].additionalProductId`,
                        additionalProductIdErrors,
                    );
                }
            }
        });
    });

    return shipmentErrors;
};

export const validateAdditionalProductId = (
    additionalProductId: AdditionalProductId,
): AdditionalProductIdErrors | undefined => {
    const errors: AdditionalProductIdErrors = {};
    if (
        additionalProductId.softwareStatus !== undefined &&
        !SOFTWARE_STATUS_PATTERN.test(additionalProductId.softwareStatus)
    ) {
        errors.softwareStatus = AdditionalProductIdFieldError.INVALID_STATUS;
    }
    if (
        additionalProductId.hardwareStatus !== undefined &&
        !HARDWARE_STATUS_PATTERN.test(additionalProductId.hardwareStatus)
    ) {
        errors.hardwareStatus = AdditionalProductIdFieldError.INVALID_STATUS;
    }
    if (
        additionalProductId.partsGenerationStatus !== undefined &&
        !PARTS_GENERATION_STATUS_PATTERN.test(additionalProductId.partsGenerationStatus)
    ) {
        errors.partsGenerationStatus = AdditionalProductIdFieldError.INVALID_STATUS;
    }
    return Object.keys(errors).length > 0 ? errors : undefined;
};

export const validateBatchNumber = (batchNumber?: string): string | undefined => {
    if (batchNumber === undefined) {
        return undefined;
    }
    if (batchNumber.length > 15) {
        return FieldErrorType.TOO_LONG;
    }
    if (!/^[A-Z0-9/-]+$/.test(batchNumber)) {
        return FieldErrorType.CONTAINS_INVALID_CHARACTERS;
    }
    return undefined;
};

export const validateNumberInRange = (value: number, minValue: number, maxValue: number, intl: IntlShape): any => {
    if (value < minValue) {
        return `${intl.formatMessage({ id: 'webedi.error.validation.notLower' })} ${minValue}`;
    } else if (value > maxValue) {
        return `${intl.formatMessage({ id: 'webedi.error.validation.notHigher' })} ${maxValue}`;
    }
    return undefined;
};

export const normalizeCurrency = (value: string) => {
    const strippedValue = value.replace(/[^0-9.]+/g, '');
    const [integral, decimal] = strippedValue.split('.');

    const newValue = decimal ? `${integral}.${decimal.slice(0, 2)}` : integral;

    return parseFloat(newValue);
};
