import Dialog from '@rio-cloud/rio-uikit/Dialog';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { FormApi } from 'final-form';
import { noop } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { ProblemError } from '../../../api/apiUtils';
import {
    useCreateCourierOrderMutation,
    useGetCourierShippingOptionsMutation,
} from '../../../api/dispatchProposal/courierOrderIntent/courierOrderIntentApi';
import { CourierOrder, CourierOrderDetails } from '../../../domain/courierOrderIntent.types';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { courierOrderIntentSlice } from '../../../reducers/courierOrderIntent/CourierOrderIntentReducer';
import {
    getIsDialogOpen,
    getSelectedDispatchProposalId,
} from '../../../selectors/courierOrderIntent/CourierOrderIntent.selector';
import { getUserProfile } from '../../../selectors/login/Login.selector';
import { Wizard } from './CourierOrderIntentWizard';
import { CourierForm, fieldNames } from './courierOrderIntentDialog.types';
import { validateFirstStep, validateSecondStep } from './courierOrderIntentDialogValidation';
import { AcceptTransmissionOfDataCheckbox } from './firstStep/AcceptTransmissionOfDataCheckbox';
import { CourierOrderIntentContactDetails } from './firstStep/CourierOrderIntentContactDetails';
import { CourierOrderIntentInfoBanner } from './firstStep/CourierOrderIntentInfoBanner';
import {
    CourierOrderIntentPickupSelection,
    getInitialValuesForPickupSelection,
} from './firstStep/CourierOrderIntentPickupSelection';
import { TermsConditionsCheckbox } from './firstStep/TermsConditionsCheckbox';
import { ServiceLevelSelection } from './secondStep/ServiceLevelSelection';
import { ServiceProviderInfoBanner } from './secondStep/ServiceProviderInfoBanner';
import { ServiceProviderSelection } from './secondStep/ServiceProviderSelection';
import { OrderCourierSuccessPage } from './thirdStep/OrderCourierSuccessPage';

export const CourierOrderIntentDialog = () => {
    const isDialogOpen = useAppSelector(getIsDialogOpen);
    const dispatch = useAppDispatch();

    const closeDialog = () => {
        dispatch(courierOrderIntentSlice.actions.stopCourierOrderIntentCommissioning());
    };
    // Footer is included in the DialogBody so that we can control validation and progress within the Wizard
    return (
        <Dialog
            body={<DialogBody />}
            bodyClassName={'margin-0 padding-0'}
            footer={undefined}
            bsSize={Dialog.SIZE_LG}
            title={<FormattedMessage id={'webedi.dispatchProposals.expressParcel.dialog.title'} />}
            show={isDialogOpen}
            showCloseButton
            onClose={closeDialog}
            onHide={closeDialog}
        />
    );
};

const DialogBody = () => {
    const userProfile = useAppSelector(getUserProfile);
    const [showErrorBanner, setShowErrorBanner] = useState(false);
    const [errorCode, setErrorCode] = useState<string | undefined>(undefined);
    const [getOptions, getOptionsResult] = useGetCourierShippingOptionsMutation();
    const [createOrder, createOrderResult] = useCreateCourierOrderMutation();
    const dispatchProposalId = useSelector(getSelectedDispatchProposalId);
    const duns = useDunsNumberFromPath();
    const expressData = getOptionsResult.data;
    const [currentPage, setCurrentPage] = useState(0);
    const formApiRef = useRef<FormApi<CourierForm>>();
    const dispatch = useAppDispatch();

    useEffect(() => {
        formApiRef.current?.initialize({
            [fieldNames.senderFirstAndLastName]: `${userProfile?.givenName} ${userProfile?.familyName}`,
            [fieldNames.senderPhoneNumber]: userProfile?.mobilePhone,
            ...getInitialValuesForPickupSelection(),
        });
    }, [userProfile]);

    const clearErrorBanner = () => {
        setErrorCode(undefined);
        setShowErrorBanner(false);
    };

    const previousPage = () => {
        if (currentPage > 0 && formApiRef.current) {
            setCurrentPage((prevPage) => prevPage - 1);
            clearErrorBanner();
        }
    };

    const nextPage = async () => {
        if (formApiRef.current) {
            const { errors, values } = formApiRef.current.getState();
            const performFirstPageAction = async () => {
                try {
                    clearErrorBanner();
                    const data = await getOptions({
                        dunsNumber: duns ?? '',
                        dispatchProposalId: dispatchProposalId ?? '',
                        pickupSelection: {
                            pickupDate: values.pickupDate!,
                            pickupEarliestTime: values.pickupEarliestTime!,
                            pickupLatestTime: values.pickupLatestTime!,
                        },
                    }).unwrap();

                    const currState = formApiRef.current?.getState().values;
                    formApiRef.current?.initialize({
                        ...currState,
                        [fieldNames.serviceProviderSelection]: data.serviceProvider,
                        [fieldNames.serviceLevelSelection]:
                            currState?.serviceLevelSelection ||
                            data.serviceLevels.find((item) => !item.requiresApproval)?.id,
                    });
                    setCurrentPage((prevPage) => prevPage + 1);
                } catch (e) {
                    const err = toErrCode(e as Error);
                    setErrorCode(err);
                    setShowErrorBanner(true);
                    return;
                }
            };
            const performSecondPageAction = async () => {
                try {
                    clearErrorBanner();
                    const requestBody: CourierOrderDetails = {
                        contactDetails: {
                            senderName: values.senderFirstAndLastName!,
                            senderPhone: values.senderPhoneNumber!,
                            recipientName: values.recipientFirstAndLastName!,
                            recipientPhone: values.recipientPhoneNumber!,
                        },
                        pickupSelection: {
                            pickupDate: values.pickupDate!,
                            pickupEarliestTime: values.pickupEarliestTime!,
                            pickupLatestTime: values.pickupLatestTime!,
                        },
                        courierSelection: {
                            serviceProvider: values.serviceProviderSelection!,
                            serviceLevel: values.serviceLevelSelection!,
                            approvalPerson: values.approvingPerson,
                            approvalCostCenter: values.approvingCostCenter,
                        },
                    };
                    const responseBody: CourierOrder = await createOrder({
                        dunsNumber: duns ?? '',
                        dispatchProposalId: dispatchProposalId ?? '',
                        createOrderDetails: requestBody,
                    }).unwrap();
                    dispatch(courierOrderIntentSlice.actions.setCourierOrder(responseBody));
                    setCurrentPage((prevPage) => prevPage + 1);
                } catch (e) {
                    const err = toErrCode(e as Error);
                    setErrorCode(err);
                    setShowErrorBanner(true);
                    return;
                }
            };

            formApiRef.current.submit();
            if (Object.keys(errors!).length === 0) {
                if (currentPage === 0) {
                    await performFirstPageAction();
                }

                if (currentPage === 1) {
                    await performSecondPageAction();
                }
            }
        }
    };

    const toErrCode = (e: any) => {
        if (e?.data?.hasOwnProperty('errorCode')) {
            return (e.data as ProblemError).errorCode;
        }
        return undefined;
    };

    return (
        <Form<CourierForm>
            onSubmit={noop}
            validate={
                currentPage === 0 ? validateFirstStep : (values) => validateSecondStep(values, getOptionsResult.data)
            }
        >
            {({ handleSubmit, form }) => {
                formApiRef.current = form;
                return (
                    <>
                        <Spinner
                            show={getOptionsResult.isLoading || createOrderResult.isLoading}
                            isDoubleSized={true}
                            isFullSized={true}
                        />
                        <Wizard
                            currentPage={currentPage}
                            onSubmit={handleSubmit}
                            onNext={nextPage}
                            onPrevious={previousPage}
                        >
                            <Wizard.Step key={'step-1'}>
                                <CourierOrderIntentInfoBanner showErrorBanner={showErrorBanner} errorCode={errorCode} />
                                <CourierOrderIntentContactDetails />
                                <CourierOrderIntentPickupSelection />
                                <CourierOrderIntentAcceptCheckboxes />
                            </Wizard.Step>
                            <Wizard.Step key={'step-2'}>
                                <ServiceProviderInfoBanner
                                    data-testid={'secondStep'}
                                    showErrorBanner={showErrorBanner}
                                    errorCode={errorCode}
                                />
                                <ServiceProviderSelection serviceProvider={expressData?.serviceProvider} />
                                <ServiceLevelSelection initialOptions={expressData?.serviceLevels} />
                            </Wizard.Step>
                            <Wizard.Step key={'step-3'}>
                                <OrderCourierSuccessPage />
                            </Wizard.Step>
                        </Wizard>
                    </>
                );
            }}
        </Form>
    );
};

const CourierOrderIntentAcceptCheckboxes = () => {
    return (
        <div className={'display-flex content-center'}>
            <div>
                <TermsConditionsCheckbox />
                <AcceptTransmissionOfDataCheckbox />
            </div>
        </div>
    );
};
