import ClearableInput from '@rio-cloud/rio-uikit/ClearableInput';
import { Dispatch, KeyboardEvent, SetStateAction, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { Routing } from '../../routing/Routes';

export const DUNS_SELECTION_INPUT_TEST_ID = 'DUNS_SELECTION_INPUT_TEST_ID';
export const DUNS_SELECTION_BUTTON_TEST_ID = 'DUNS_SELECTION_BUTTON_TEST_ID';

export const TriangularProcessDunsSelector = (props: {
    setShowPopover?: Dispatch<SetStateAction<boolean>>;
}) => {
    const [selectedDuns, setSelectedDuns] = useState('');
    const isDunsNumberSet = () => selectedDuns !== undefined && selectedDuns.trim().length > 0;
    const navigate = useNavigate();

    const switchView = () => {
        if (isDunsNumberSet()) {
            props.setShowPopover?.(false);
            navigate(`${Routing.webScm}/${selectedDuns}${Routing.deliverySchedules}`);
        }
    };
    const onKeyPress = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            switchView();
        }
    };

    return (
        <>
            <label>
                <FormattedMessage id={'webedi.triangularProcess.input.duns'} />
            </label>
            <ClearableInput
                maxLength={9}
                data-testid={DUNS_SELECTION_INPUT_TEST_ID}
                onChange={setSelectedDuns}
                onKeyPress={onKeyPress}
            />
            <button
                type={'button'}
                className={`btn btn-primary btn-block margin-top-10 ${!isDunsNumberSet() && 'disabled'}`}
                onClick={switchView}
                data-testid={DUNS_SELECTION_BUTTON_TEST_ID}
            >
                <span className={'rioglyph rioglyph-refresh'} />
                <span>
                    {' '}
                    <FormattedMessage id={'webedi.triangularProcess.button.reload'} />
                </span>
            </button>
        </>
    );
};
