import Divider from '@rio-cloud/rio-uikit/Divider';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Popover from '@rio-cloud/rio-uikit/Popover';
import RadioButton from '@rio-cloud/rio-uikit/RadioButton';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { useDunsNumberFromPath, useLoggedInUserDunsNumber } from '../../../hooks/Routing.hooks';
import { Routing } from '../../routing/Routes';
import { TriangularProcessDunsSelector } from '../triangularProcess/TriangularProcessDunsSelector';

export const VW_TRIANGULAR_PROCESS_DUNS_SELECTOR = 'VW_TRIANGULAR_PROCESS_DUNS_SELECTOR';
export const VW_TRIANGULAR_PROCESS_POP_OVER_DUNS_SELECTOR = 'VW_TRIANGULAR_PROCESS_POP_OVER_DUNS_SELECTOR';
export const VW_TRIANGULAR_PROCESS_MAIN_DUNS_NUMBER = 'VW_TRIANGULAR_PROCESS_MAIN_DUNS_NUMBER';

export const VwTriangularProcessDunsSelector = () => {
    const loggedInUserDunsNumber = useLoggedInUserDunsNumber();
    const dunsNumberSelected = useDunsNumberFromPath();
    const mainDunsSelected = dunsNumberSelected === loggedInUserDunsNumber;
    const navigate = useNavigate();
    const [showPopover, setShowPopover] = useState(false);

    const switchView = (selectedDuns: string) => {
        setShowPopover(false);
        navigate(`${Routing.webScm}/${selectedDuns}${Routing.deliverySchedules}`);
    };

    return (
        <OverlayTrigger
            trigger='click'
            placement='bottom-end'
            show={showPopover}
            onToggle={setShowPopover}
            overlay={
                <Popover
                    id='popoverVwTriangularProcessDunsSelector'
                    data-testid={VW_TRIANGULAR_PROCESS_POP_OVER_DUNS_SELECTOR}
                    titleClassName='bg-lightest rounded-top'
                    contentClassName='padding-0'
                    className={'width-300'}
                >
                    <Popover.Title className='bg-lightest rounded-top'>
                        <div className={'text-uppercase bold'}>
                            <FormattedMessage id={'webedi.triangularProcess.vw.popover.title'} />
                        </div>
                    </Popover.Title>
                    <Popover.Content className='padding-10'>
                        <div>
                            <FormattedMessage id={'webedi.triangularProcess.vw.popover.mainDuns'} />:
                            <RadioButton
                                name='radios'
                                id='selectedDuns'
                                data-testid={VW_TRIANGULAR_PROCESS_MAIN_DUNS_NUMBER}
                                value={loggedInUserDunsNumber}
                                defaultChecked={mainDunsSelected}
                                onChange={() => switchView(loggedInUserDunsNumber!)}
                            >
                                {loggedInUserDunsNumber}
                            </RadioButton>
                        </div>
                        <Divider />
                        <TriangularProcessDunsSelector setShowPopover={setShowPopover} />
                    </Popover.Content>
                </Popover>
            }
            popperConfig={{
                modifiers: [
                    { name: 'offset', options: { offset: [50, 15] } },

                    // 5px from the edges of the popper due to rounded corners for the popover
                    { name: 'arrow', options: { padding: 5 } },
                ],
            }}
        >
            <div className={'text-left'} data-testid={VW_TRIANGULAR_PROCESS_DUNS_SELECTOR}>
                {loggedInUserDunsNumber}
                <span className={'icon rioglyph rioglyph-chevron-down margin-left-5'} />
            </div>
        </OverlayTrigger>
    );
};
