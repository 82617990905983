import { UserManager } from 'oidc-client-ts';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Route, RouterProvider, createBrowserRouter } from 'react-router';

import { applyConfigForIdp, config } from './config';

import { handleLoginRedirect, main, store } from './configuration';
import { FeatureToggleProvider } from './configuration/featureToggle/FeatureToggleProvider';
import { createUserManager } from './configuration/login/login';
import { attemptInitialSignIn } from './configuration/setup/oauth';
import { trace } from './configuration/setup/trace';
import { AppContainer } from './features/app/components/App.container';
import { ErrorBoundary } from './features/app/components/ErrorBoundary';
import { NoMatch } from './features/app/components/NoMatch';
import './polyfills';
import './LanguageChangedListener';
import { createRoutesFromChildren } from 'react-router';

const renderApplication = (userManager: UserManager) => {
    const container = document.getElementById('root');
    const root = createRoot(container!);

    const router = createBrowserRouter(
        createRoutesFromChildren(
            <>
                <Route path={'/error'} element={<NoMatch />} />
                <Route path={'*'} element={<AppContainer userManager={userManager} />} />
            </>,
        ),
    );

    root.render(
        <ErrorBoundary>
            <Provider store={store}>
                <FeatureToggleProvider>
                    <RouterProvider router={router} />
                </FeatureToggleProvider>
            </Provider>
        </ErrorBoundary>,
    );
};

applyConfigForIdp();

const userManager = main(createUserManager());

const login = () =>
    attemptInitialSignIn(userManager)
        .then(() => renderApplication(userManager))

        .catch((error: any) => {
            trace('could not start application', error);
        });

if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect(userManager).then(login);
} else {
    login();
}
