import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { FormattedMessage } from 'react-intl';
import { useDunsNumberFromPath, useLoggedInUserDunsNumber } from '../../../hooks/Routing.hooks';

export const MENU_TRIANGULAR_PROCESS_VIEW_NAME = 'MENU_TRIANGULAR_PROCESS_VIEW_NAME';

export const TriangularProcessViewName = () => {
    const dunsNumber = useDunsNumberFromPath();
    const loggedInUserDuns = useLoggedInUserDunsNumber();

    const userProfile = (
        <>
            {dunsNumber === loggedInUserDuns ? (
                <FormattedMessage id={'webedi.triangularProcess.header.view.consignor'} />
            ) : (
                <FormattedMessage id={'webedi.triangularProcess.header.view.seller'} />
            )}
        </>
    );
    return (
        <ActionBarItem id={'triangularProcessViewName'} className={'company-name-action-bar-container'}>
            <div className={'action-bar-item'}>
                <div className={'text-left'} data-testid={MENU_TRIANGULAR_PROCESS_VIEW_NAME}>
                    <label>
                        <FormattedMessage id={'webedi.triangularProcess.header.view.title'} />
                    </label>
                    <OverlayTrigger placement={'bottom'} overlay={<Tooltip id='tooltip'>{userProfile} </Tooltip>}>
                        <div className={'ellipsis-1 max-width-100'}>
                            <span>{userProfile}</span>
                        </div>
                    </OverlayTrigger>
                </div>
            </div>
        </ActionBarItem>
    );
};
