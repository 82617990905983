import { SelectOption } from '@rio-cloud/rio-uikit';
import Select from '@rio-cloud/rio-uikit/Select';
import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router';
import { useAppSelector } from '../../../../../../configuration/setup/typedReduxHooks';
import { useDunsNumberFromPath } from '../../../../hooks/Routing.hooks';
import {
    FreightForwarder,
    FreightForwarderFromTransportOrder,
    ManagedFreightForwarder,
} from '../../../../reducers/transportation/types';
import { getManagedFreightForwarders } from '../../../../selectors/masterData/MasterData.selector';
import { getSelectedShipment } from '../../../../selectors/shipments/Shipments.selector';
import { Routing } from '../../../routing/Routes';
import { AssignedFreightForwarder } from '../../AssignedFreightForwarder';
import { ReadOnlyCarrierInput } from '../../ReadOnlyCarrierInput';
import { ASSIGN_FREIGHT_FORWARDER_TEST_ID } from './TransportEdit';

export const READ_ONLY_CARRIER_INPUT_TEST_ID = 'READ_ONLY_CARRIER_INPUT_TEST_ID';
export const FreightForwarderEdit = () => {
    const shipment = useAppSelector(getSelectedShipment);

    return (
        <div
            data-testid={ASSIGN_FREIGHT_FORWARDER_TEST_ID}
            className={'display-flex-sm flex-row align-items-center width-100pct'}
        >
            <Field<FreightForwarder> name={'freightForwarder'}>
                {(fieldProps) =>
                    fieldProps.input.value.type === undefined ||
                    fieldProps.input.value.type === 'MANAGED_FREIGHT_FORWARDER' ? (
                        <ManagedFreightForwarderSelect
                            managedFreightForwarder={fieldProps.input.value}
                            onChange={fieldProps.input.onChange}
                        />
                    ) : (
                        <ReadOnlyFreightForwarderFromTransportOrderInput freightForwarder={fieldProps.input.value} />
                    )
                }
            </Field>
            {shipment?.transportOrderReference !== undefined && (
                <div className={'width-33pct-sm'} data-testid={READ_ONLY_CARRIER_INPUT_TEST_ID}>
                    <ReadOnlyCarrierInput carrier={shipment.carrier} />
                </div>
            )}
        </div>
    );
};

const makeManagedFreightForwarderOptions = (managedFreightForwarders: ManagedFreightForwarder[]): SelectOption[] =>
    managedFreightForwarders
        .map(formatManagedFreightForwarderForSelect)
        .sort((a, b) => String(a.label).localeCompare(String(b.label)));

export const formatManagedFreightForwarderForSelect = (
    managedFreightForwarder: ManagedFreightForwarder,
): SelectOption => {
    const label: string = managedFreightForwarder.name[0];
    return { id: managedFreightForwarder.id, label };
};

const findSelectedManagedFreightForwarder = (
    id: string,
    managedFreightForwarders: ManagedFreightForwarder[],
): ManagedFreightForwarder | undefined => managedFreightForwarders.find((it) => it.id === id);

const ManagedFreightForwarderSelect = (props: {
    managedFreightForwarder: ManagedFreightForwarder;
    onChange: (_: ManagedFreightForwarder | undefined) => void;
}) => {
    const managedFreightForwarders = useAppSelector(getManagedFreightForwarders);
    const duns = useDunsNumberFromPath();

    const { managedFreightForwarder, onChange } = props;

    return (
        <>
            <div className={'width-33pct-sm'}>
                <label>
                    <FormattedMessage id={'webedi.freightForwarder'} />
                </label>
                <div className={'padding-right-15 margin-bottom-10'}>
                    <Select
                        useFilter={true}
                        onChange={(event?: SelectOption) =>
                            onChange(event && findSelectedManagedFreightForwarder(event.id, managedFreightForwarders))
                        }
                        options={makeManagedFreightForwarderOptions(managedFreightForwarders)}
                        placeholder={<FormattedMessage id={'webedi.inputPlaceholder.dropdown.standard'} />}
                        value={
                            Array.isArray(managedFreightForwarder.id)
                                ? managedFreightForwarder.id
                                : [managedFreightForwarder.id]
                        }
                    />
                </div>
                <NavLink
                    to={`${Routing.webScm}/${duns}${Routing.carriers}`}
                    className={'margin-top-10 text-decoration-none'}
                >
                    <span className={'rioglyph rioglyph-plus margin-right-5'} />
                    <FormattedMessage id={'webedi.transport.createCarrierHint'} />
                </NavLink>
            </div>
            <div className={'width-66pct-sm margin-top-15 padding-right-15'}>
                {managedFreightForwarder && <AssignedFreightForwarder freightForwarder={managedFreightForwarder} />}
            </div>
        </>
    );
};

const ReadOnlyFreightForwarderFromTransportOrderInput = (props: {
    freightForwarder: FreightForwarderFromTransportOrder;
}) => (
    <div className={'width-33pct-sm'}>
        <label>
            <FormattedMessage id={'webedi.freightForwarder'} />
        </label>
        <div className={'padding-right-15 margin-bottom-10'}>
            <input className={'form-control'} type={'text'} disabled={true} value={props.freightForwarder.name[0]} />
        </div>
    </div>
);
