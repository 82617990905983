import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/typedReduxHooks';
import { getCompanyInfo } from '../../../actions/ui/CompanyInfo.actions';
import { useDunsNumberFromPath } from '../../../hooks/Routing.hooks';
import { getCompanyName } from '../../../selectors/ui/CompanyInfo.selector';

export const MENU_COMPANY_NAME = 'MENU_COMPANY_NAME';

export const VwCompanyName = () => {
    const dispatch = useAppDispatch();
    const dunsNumber = useDunsNumberFromPath();
    const companyName = useAppSelector(getCompanyName);

    useEffect(() => {
        dunsNumber && dispatch(getCompanyInfo(dunsNumber));
    }, [dunsNumber, dispatch]);

    return (
        <ActionBarItem id={'companyName'} className={'company-name-action-bar-container'}>
            <div className={'action-bar-item'}>
                {dunsNumber && companyName && (
                    <div className={'text-left'} data-testid={MENU_COMPANY_NAME}>
                        <label>
                            <FormattedMessage id={'webedi.companyName'} />
                        </label>
                        <OverlayTrigger placement={'bottom'} overlay={<Tooltip id='tooltip'>{companyName} </Tooltip>}>
                            <div className={'ellipsis-1 max-width-250'}>
                                <span>{companyName}</span>
                            </div>
                        </OverlayTrigger>
                    </div>
                )}
            </div>
        </ActionBarItem>
    );
};
