import { Middleware, isRejectedWithValue } from '@reduxjs/toolkit';
import { TranslationKeys } from '../../../configuration/lang/types';
import translationKeys from '../../translations/en-GB.json';
import { nonVanishingFormattedErrorNotification } from '../actions/NotificationUtils';
import { RTKQueryErrorResponse } from './apiUtils';

export const rtkGlobalErrorHandler =
    (): Middleware =>
    () =>
    (next) =>
    // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: Legacy code
    (action) => {
        if (isRejectedWithValue(action) && !isApiIgnored(action.type)) {
            if (hasErrorPayload(action)) {
                const errorCode = action.payload.data?.errorCode;
                if (errorCode && blacklistedCodesThatShouldNotBeHandledGlobally.includes(errorCode)) {
                    return next(action);
                }
                if (errorIndicatesServerMaintenance(action.payload.data?.status, action.payload.data?.detail)) {
                    window.location.href = '/maintenance';
                    return next(action);
                }
                const translationKey = `webedi.error.${errorCode}`;
                nonVanishingFormattedErrorNotification({
                    messageId:
                        errorCode && errorCodeHasTranslation(translationKey) ? translationKey : 'webedi.error.unknown',
                    errorCode,
                });
            } else {
                nonVanishingFormattedErrorNotification({ messageId: 'webedi.error.unknown' });
            }
        }

        return next(action);
    };

const errorIndicatesServerMaintenance = (statusCode?: number, detail?: string): boolean =>
    statusCode === 503 && (detail ?? '').includes('The service is currently undergoing maintenance');

const isApiIgnored = (api: string) => {
    return ignoredApisFromGlobalErrorHandling.filter((value) => api.includes(value))?.length > 0;
};
const hasErrorPayload = (action: unknown): action is { payload: RTKQueryErrorResponse } => {
    if (!actionHasPayload(action)) {
        return false;
    }
    const payload = action.payload;
    return isObject(payload) && Object.hasOwn(payload, 'status') && Object.hasOwn(payload, 'data');
};

const actionHasPayload = (action: unknown): action is { payload: unknown } =>
    isObject(action) && Object.hasOwn(action, 'payload');

const isObject = (input: unknown): input is Record<string, unknown> =>
    input != null && typeof input === 'object' && !Array.isArray(input);

export const blacklistedCodesThatShouldNotBeHandledGlobally = [
    'UPS_120217',
    'UPS_9510110',
    'UPS_9510122',
    'UPS_9510114',
    'UPS_9510115',
    'DHL_202062',
    'DHL_202113',
    'DHL_5005',
    'DHL_5001',
];

const ignoredApisFromGlobalErrorHandling = ['emailNotificationApi'];

export const errorCodeHasTranslation = (translationKeyCandidate: string): translationKeyCandidate is TranslationKeys =>
    Object.hasOwn(translationKeys, translationKeyCandidate);
