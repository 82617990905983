import { useEffect } from 'react';
import { Field, FieldRenderProps, useField, useForm } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { isAfter, isNowBeforeNoon, isSameOrAfter, isValidIso8601, today } from '../../../../../../dateUtils';
import { DatePicker } from '../../../common/DatePicker';
import { TimePicker } from '../../../common/TimePicker';
import { CourierForm, fieldNames } from '../courierOrderIntentDialog.types';
import {
    calculateDefaultPickupDate,
    calculateEarliestPickupTime,
    calculateLatestPickupTime,
} from './courierOrderIntentPickupSelectionService';

const useSavePickupSelection = (): void => {
    const field = useField(fieldNames.pickupSelection, { subscription: { value: true } });
    const value = field.input.value;

    return useEffect(() => {
        savePickupSelectionFieldToLocalStorage(value);
    }, [value]);
};

export const CourierOrderIntentPickupSelection = () => {
    useSavePickupSelection();

    return (
        <div className='panel panel-default display-flex flex-column justify-content-center align-items-stretch padding-20 gap-25'>
            <div className='text-size-14 text-medium padding-x-10'>
                <span className='rioglyph rioglyph-van text-size-18 padding-right-5' />
                <FormattedMessage id={'webedi.dispatchProposals.expressParcel.dialog.firstStep.pickup.title'} />
            </div>
            <div className={'alert alert-info padding-10'}>
                <div className={'display-flex gap-10'}>
                    <span className={'text-color-info text-size-h4 rioglyph rioglyph-info-sign'} />
                    <div className={'text-color-info'}>
                        <FormattedMessage id={'webedi.dispatchProposals.expressParcel.dialog.firstStep.pickup.info'} />
                    </div>
                </div>
            </div>
            <PickUpFields />
        </div>
    );
};

const PickUpFields = () => {
    const { change } = useForm();

    return (
        <div className='display-flex flex-row gap-25 width-100pc justify-content-center'>
            <Field name={fieldNames.pickupDate} component='input' type='hidden'>
                {({ input, meta }: FieldRenderProps<string>) => (
                    <>
                        <div className='width-200-sm'>
                            <label className='text-size-14'>
                                <FormattedMessage
                                    id={
                                        'webedi.dispatchProposals.expressParcel.dialog.firstStep.pickup.pickupDate.title'
                                    }
                                />{' '}
                                *
                            </label>
                            <div
                                className={`form-group ${meta.error && 'has-error has-feedback'} `}
                                data-testid={'pickupDate'}
                            >
                                <DatePicker
                                    initialValue={calculateDefaultPickupDate(input.value)}
                                    onChange={(value?: string) => {
                                        change(input.name, value);
                                    }}
                                    isValidDate={isValidDateForFromSelection}
                                    className={'margin-0'}
                                />
                                {meta.error && (
                                    <span className='help-block'>
                                        <FormattedMessage id={meta.error} />
                                    </span>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </Field>
            <Field name={fieldNames.pickupEarliestTime} component='input' type='hidden'>
                {({ input, meta }: FieldRenderProps<string>) => (
                    <>
                        <div className='width-200-sm'>
                            <label className='text-size-14'>
                                <FormattedMessage
                                    id={
                                        'webedi.dispatchProposals.expressParcel.dialog.firstStep.pickup.pickupEarliestTime.title'
                                    }
                                />{' '}
                                *
                            </label>
                            <div
                                className={`form-group ${meta.error && 'has-error has-feedback'} `}
                                data-testid={'pickupEarliestTime'}
                            >
                                <TimePicker
                                    value={input.value}
                                    onChange={(value?: string) => change(input.name, value)}
                                />
                                {meta.error && (
                                    <span className='help-block'>
                                        <FormattedMessage id={meta.error} />
                                    </span>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </Field>
            <Field name={fieldNames.pickupLatestTime} component='input' type='hidden'>
                {({ input, meta }: FieldRenderProps<string>) => (
                    <>
                        <div className='width-200-sm'>
                            <label className='text-size-14'>
                                <FormattedMessage
                                    id={
                                        'webedi.dispatchProposals.expressParcel.dialog.firstStep.pickup.pickupLatestTime.title'
                                    }
                                />{' '}
                                *
                            </label>
                            <div
                                className={`form-group ${meta.error && 'has-error has-feedback'} `}
                                data-testid={'pickupLatestTime'}
                            >
                                <TimePicker
                                    value={input.value}
                                    onChange={(value?: string) => change(input.name, value)}
                                />
                                {meta.error && (
                                    <span className='help-block'>
                                        <FormattedMessage id={meta.error} />
                                    </span>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </Field>
        </div>
    );
};

const isValidDateForFromSelection = (currentDate?: string): boolean => {
    if (!isValidIso8601(currentDate)) {
        return false;
    }

    return isNowBeforeNoon() ? isSameOrAfter(currentDate, today()) : isAfter(currentDate, today());
};

const pickupSelectionLocalStorageKey = 'epPickupSelection';

export const savePickupSelectionFieldToLocalStorage = (value: string) => {
    try {
        if (value) {
            localStorage.setItem(pickupSelectionLocalStorageKey, value);
        }
    } catch {}
};

export const getInitialValuesForPickupSelection = (): Partial<CourierForm> => ({
    [fieldNames.pickupDate]: calculateDefaultPickupDate(),
    [fieldNames.pickupEarliestTime]: calculateEarliestPickupTime(),
    [fieldNames.pickupLatestTime]: calculateLatestPickupTime(),
});
