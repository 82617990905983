import { ExpressShipmentServiceProvider } from '../../../domain/courierOrderIntent.types';

export enum CourierOrderIntentDialogFormFieldError {
    SHIPPER_NAME_AND_LASTNAME_ABSENT = 'webedi.dispatchProposals.expressParcel.dialog.firstStep.shipperForm.error.firstAndLastNameAbsent',
    SHIPPER_PHONE_NUMBER_ABSENT = 'webedi.dispatchProposals.expressParcel.dialog.firstStep.shipperForm.error.phoneNumberAbsent',
    RECIPIENT_NAME_AND_LASTNAME_ABSENT = 'webedi.dispatchProposals.expressParcel.dialog.firstStep.recipientForm.error.firstAndLastNameAbsent',
    RECIPIENT_PHONE_NUMBER_ABSENT = 'webedi.dispatchProposals.expressParcel.dialog.firstStep.recipientForm.error.phoneNumberAbsent',
    TERMS_AND_CONDITIONS_CHECKBOX_UNCHECKED = 'webedi.dispatchProposals.expressParcel.dialog.firstStep.error.acceptTermsAndConditions',
    TRANSMISSION_OF_DATA_ALLOWED_CHECKBOX_UNCHECKED = 'webedi.dispatchProposals.expressParcel.dialog.firstStep.error.acceptTransmissionOfData',
    PICKUP_DATE_ABSENT = 'webedi.dispatchProposals.expressParcel.dialog.firstStep.error.required',

    PICKUP_EARLIEST_TIME_ABSENT = 'webedi.dispatchProposals.expressParcel.dialog.firstStep.error.required',
    PICKUP_EARLIEST_TIME_TOO_SOON = 'webedi.dispatchProposals.expressParcel.dialog.firstStep.error.pickupEarliestTime.tooSoon',
    PICKUP_EARLIEST_TIME_AFTER_LATEST_TIME = 'webedi.dispatchProposals.expressParcel.dialog.firstStep.error.pickupEarliestTime.after.pickupLatestTime',

    PICKUP_LATEST_TIME_ABSENT = 'webedi.dispatchProposals.expressParcel.dialog.firstStep.error.required',
    SERVICE_LEVEL_SELECTION_APPROVING_PERSON_ABSENT = 'webedi.dispatchProposals.expressParcel.dialog.secondStep.error.approvingPersonAbsent',
    SERVICE_LEVEL_SELECTION_APPROVING_COSTCENTER_ABSENT = 'webedi.dispatchProposals.expressParcel.dialog.secondStep.error.approvingCostCenterAbsent',
}

export interface CourierForm {
    senderFirstAndLastName?: string;
    senderPhoneNumber?: string;
    recipientFirstAndLastName?: string;
    recipientPhoneNumber?: string;
    pickupDate?: string;
    pickupEarliestTime?: string;
    pickupLatestTime?: string;
    termsAndConditions?: boolean;
    transmissionOfData?: boolean;
    serviceProviderSelection?: ExpressShipmentServiceProvider;
    serviceLevelSelection?: string;
    approvingPerson?: string;
    approvingCostCenter?: string;
}

export const fieldNames = {
    senderFirstAndLastName: 'senderFirstAndLastName',
    senderPhoneNumber: 'senderPhoneNumber',
    recipientFirstAndLastName: 'recipientFirstAndLastName',
    recipientPhoneNumber: 'recipientPhoneNumber',
    pickupSelection: 'pickupSelection',
    pickupDate: 'pickupDate',
    pickupEarliestTime: 'pickupEarliestTime',
    pickupLatestTime: 'pickupLatestTime',
    termsAndConditions: 'termsAndConditions',
    transmissionOfData: 'transmissionOfData',
    serviceProviderSelection: 'serviceProviderSelection',
    serviceLevelSelection: 'serviceLevelSelection',
    approvingPerson: 'approvingPerson',
    approvingCostCenter: 'approvingCostCenter',
};

export type FieldNames = (typeof fieldNames)[keyof typeof fieldNames];
