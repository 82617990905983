import { useMatch } from 'react-router';
import { useAppSelector } from '../../../configuration/setup/typedReduxHooks';
import { getDunsNumberFromToken } from '../../../configuration/tokenHandling/selectors';
import { LOGGED_IN_USER_DUNS } from '../../../utils';
import { Routing } from '../components/routing/Routes';

export const useDunsNumberFromPath = (): string | undefined => {
    const match = useMatch(`${Routing.webScm}/:dunsNumber/*`);
    return match?.params.dunsNumber;
};

export const useShipmentIdFromPath = (): string | undefined => {
    const matchShipment = useMatch(`${Routing.webScm}/:dunsNumber/shipments/:shipmentId`);
    const matchExportedShipment = useMatch(`${Routing.webScm}/:dunsNumber/exported-shipments/:shipmentId`);

    return matchShipment?.params.shipmentId || matchExportedShipment?.params.shipmentId;
};

export const useDeliveryScheduleIdFromPath = (): string | undefined => {
    const match = useMatch(`${Routing.webScm}/:dunsNumber/delivery-schedules/:deliveryScheduleId/*`);
    return match?.params.deliveryScheduleId;
};

export const useLoggedInUserDunsNumber = (): string | undefined => {
    const dunsNumber = useAppSelector(getDunsNumberFromToken);
    const dunsInLocalStorage = localStorage.getItem(LOGGED_IN_USER_DUNS);
    const dunsInPath = useDunsNumberFromPath();
    if (dunsNumber) {
        return dunsNumber;
    } else if (dunsInLocalStorage) {
        return dunsInLocalStorage;
    } else {
        return dunsInPath;
    }
};
