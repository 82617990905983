import { SessionExpiredDialog } from '@rio-cloud/rio-session-expired-info';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import ApplicationLayoutBodyBanner from '@rio-cloud/rio-uikit/ApplicationLayoutBodyBanner';
import ApplicationLayoutBodyBottomBar from '@rio-cloud/rio-uikit/ApplicationLayoutBodyBottomBar';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';
import { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { Navigate, Route, Routes } from 'react-router';
import { IdentityProvider, config } from '../../../config';
import { FALLBACK_LOCALE } from '../../../configuration';
import { routeStorage } from '../../../configuration/login/storage';
import { trace } from '../../../configuration/setup/trace';
import messagesEN from '../../translations/en-GB.json';
import { InitializationState } from '../reducers/types';
import './App.css';
import { useDunsNumberFromPath } from '../hooks/Routing.hooks';
import AppHeader from './AppHeader';
import { ForbiddenPage } from './ForbiddenPage';
import { MaintenancePage } from './MaintenancePage';
import { useClosingDaysPageActive, useSubNavigation } from './NavLinks';
import { NoMatch } from './NoMatch';
import { NewVersionAvailableBox } from './common/NewVersionAvailableBox';
import { MaintenanceWindowBanner } from './common/banners/MaintenanceWindowBanner';
import { MaintenanceWindowBannerIdMigration } from './common/banners/MaintenanceWindowBannerIdMigration';
import { ArticleSuggestionsSidebar } from './dispatchProposals/ArticleSuggestionsSidebar';
import { PlanDispatchProposalSidebar } from './dispatchProposals/PlanDispatchProposalSidebar';
import { DunsSelectionView } from './dunsSelection/DunsSelectionView';
import { Routing } from './routing/Routes';
import { DeliveryNoteEditArticleSuggestionSidebar } from './shipments/shipmentEditView/deliveryNotes/DeliveryNoteEditArticleSuggestionSidebar';
import { TransportOrderSidebar } from './transportOrders/sidebar/TransportOrderSidebar';
import { AppProperties } from './types';
import { DataClassificationDisplay } from './vwSpecific/DataClassificationDisplay';
import { PrivacyPolicyDisplay } from './vwSpecific/PrivacyPolicyDisplay';
import { WebScmView } from './webscm/WebScmView';

export const App = (props: AppProperties) => {
    // biome-ignore lint/correctness/useExhaustiveDependencies: Migrated from eslint
    useEffect(() => {
        props.completeInitialization();
    }, []);

    const initialPath = () => {
        const initialRoute = routeStorage.getRoute();
        routeStorage.discardRoute();
        const url = initialRoute ? initialRoute : Routing.dunsSelection;
        trace('initialRoute lookup', initialRoute, 'result', url);
        return url;
    };

    const {
        hideSessionDialog,
        displayMessages,
        showSessionExpired,
        userLocale,
        initializationState,
        webSCMPlusFeaturesAreActive,
    } = props;

    const isClosingDaysPageActive = useClosingDaysPageActive();

    const dunsNumber = useDunsNumberFromPath();
    const subNavigation = useSubNavigation(dunsNumber, webSCMPlusFeaturesAreActive);

    if (initializationState !== InitializationState.COMPLETE || !displayMessages) {
        return (
            <IntlProvider defaultLocale={FALLBACK_LOCALE} locale={FALLBACK_LOCALE} messages={messagesEN}>
                <NotificationsContainer />
                {initializationState === InitializationState.FAILED && <NoMatch />}
            </IntlProvider>
        );
    }

    const isVWQAEnvironment = config.identityProvider === IdentityProvider.VW_QA;
    const isVWProdEnvironment = config.identityProvider === IdentityProvider.VW;
    const isVWEnvironment = isVWQAEnvironment || isVWProdEnvironment;

    const bottomBar = isVWEnvironment ? (
        <ApplicationLayoutBodyBottomBar buttonAlignment={'right'}>
            <PrivacyPolicyDisplay />
            <DataClassificationDisplay />
        </ApplicationLayoutBodyBottomBar>
    ) : undefined;

    const vwQaBanner = isVWQAEnvironment ? (
        <ApplicationLayoutBodyBanner className={'bg-secondary'}>VW-QA</ApplicationLayoutBodyBanner>
    ) : null;
    const banners = (
        <div>
            <MaintenanceWindowBanner />
            <MaintenanceWindowBannerIdMigration />
            {vwQaBanner}
        </div>
    );

    return (
        <IntlProvider defaultLocale={FALLBACK_LOCALE} key={userLocale} locale={userLocale} messages={displayMessages}>
            <ApplicationLayout className={'StarterTemplate'}>
                <AppHeader userManager={props.userManager} webSCMPlusFeaturesAreActive={webSCMPlusFeaturesAreActive} />
                <ApplicationLayout.Sidebar className={'left'}>
                    <Routes>
                        <Route
                            path={`${Routing.webScm}/:dunsNumber${Routing.shipments}/:shipmentId`}
                            element={<DeliveryNoteEditArticleSuggestionSidebar />}
                        />
                    </Routes>
                </ApplicationLayout.Sidebar>
                <ApplicationLayout.Sidebar className={'left'}>
                    <Routes>
                        <Route
                            path={`${Routing.webScm}/:dunsNumber${Routing.dispatchProposals}`}
                            element={<ArticleSuggestionsSidebar />}
                        />
                    </Routes>
                </ApplicationLayout.Sidebar>
                <ApplicationLayout.Sidebar className={'right'}>
                    <Routes>
                        <Route
                            path={`${Routing.webScm}/:dunsNumber${Routing.dispatchProposals}`}
                            element={<PlanDispatchProposalSidebar />}
                        />
                    </Routes>
                </ApplicationLayout.Sidebar>
                <ApplicationLayout.Sidebar className={'right'}>
                    <Routes>
                        <Route
                            path={`${Routing.webScm}/:dunsNumber${Routing.transportOrders}`}
                            element={<TransportOrderSidebar />}
                        />
                    </Routes>
                </ApplicationLayout.Sidebar>
                <ApplicationLayout.Body
                    data-testid={'ApplicationLayoutBody'}
                    bottomBar={bottomBar}
                    banner={banners}
                    navigation={subNavigation}
                    innerClassName={getInnerClassNameForBody(isClosingDaysPageActive)}
                >
                    <NotificationsContainer />
                    <SessionExpiredDialog locale={userLocale} onClose={hideSessionDialog} show={showSessionExpired} />
                    <NewVersionAvailableBox />
                    <Routes>
                        <Route
                            path={`${Routing.webScm}/*`}
                            element={<WebScmView webSCMPlusFeaturesAreActive={webSCMPlusFeaturesAreActive} />}
                        />
                        <Route path={`${Routing.dunsSelection}`} element={<DunsSelectionView />} />
                        <Route path={`${Routing.forbidden}`} element={<ForbiddenPage />} />
                        <Route path={`${Routing.maintenance}`} element={<MaintenancePage />} />
                        <Route path={'*'} element={<Navigate to={initialPath()} replace={true} />} />
                    </Routes>
                </ApplicationLayout.Body>
            </ApplicationLayout>
        </IntlProvider>
    );
};

const getInnerClassNameForBody = (isClosingDaysPageActive: boolean) =>
    isClosingDaysPageActive ? 'padding-0' : undefined;
