import { useBlocker } from 'react-router';
import { DiscardChangesConfirmationDialog } from './DiscardChangesConfirmationDialog';

export interface NavigationPromptProps {
    when: boolean;
}

export const NavigationPrompt = (props: NavigationPromptProps) => {
    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) => props.when && currentLocation.pathname !== nextLocation.pathname,
    );

    return (
        <DiscardChangesConfirmationDialog
            show={blocker.state === 'blocked'}
            onClickDiscard={() => blocker.state === 'blocked' && blocker.proceed()}
            onClickReturn={() => blocker.state === 'blocked' && blocker.reset()}
        />
    );
};
