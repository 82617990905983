// biome-ignore lint/style/noNamespaceImport: Prefer prefix because of shadowing from io-ts
import * as t from 'io-ts';
import { fromEnum } from '../../util';

export enum ApiExpressSelectionServiceProviderType {
    UPS = 'UPS',
    DHL = 'DHL',
}

export const expressShipmentServiceLevelCodec = t.type({
    code: t.string,
    name: t.string,
    requires_approval: t.boolean,
});

export enum ApiCourierOrderValidationError {
    WEIGHT_EXCEEDED = 'WEIGHT_EXCEEDED',
    LENGTH_EXCEEDED = 'LENGTH_EXCEEDED',
    GIRTH_EXCEEDED = 'GIRTH_EXCEEDED',
    DANGEROUS_GOODS_UNSUPPORTED = 'DANGEROUS_GOODS_UNSUPPORTED',
    NOT_WITHIN_EU = 'NOT_WITHIN_EU',
}

export const courierOrderValidationCodec = t.intersection([
    t.type({
        code: fromEnum<ApiCourierOrderValidationError>(
            'ApiCourierOrderValidationError',
            ApiCourierOrderValidationError,
        ),
    }),
    t.partial({
        actual: t.string,
        allowed: t.string,
    }),
]);

export const courierOrderValidationsArrayCodec = t.type({
    validation_errors: t.array(courierOrderValidationCodec),
});

export const expressCourierShippingOptionsCodec = t.type({
    service_provider: fromEnum<ApiExpressSelectionServiceProviderType>(
        'ApiExpressSelectionServiceProviderType',
        ApiExpressSelectionServiceProviderType,
    ),
    service_levels: t.array(expressShipmentServiceLevelCodec),
});

export const expressShipmentPickupSelectionCodec = t.partial({
    pickup_date: t.string,
    pickup_earliest_time: t.string,
    pickup_latest_time: t.string,
});

export const apiParcelCodec = t.intersection([
    t.type({
        tracking_id: t.string,
        article_numbers: t.array(t.string),
        package_item_number: t.number,
    }),
    t.partial({
        tracking_url: t.string,
    }),
]);

export const courierOrderCodec = t.type({
    printable_label: t.string,
    parcels: t.array(apiParcelCodec),
});

export const expressShipmentDetailsCodec = t.type({
    pickup_selection: expressShipmentPickupSelectionCodec,
});

export type ApiShipmentPickupSelection = t.TypeOf<typeof expressShipmentDetailsCodec>;
export type ApiExpressShipmentCourierOptions = t.TypeOf<typeof expressCourierShippingOptionsCodec>;
export type ApiExpressShipmentServiceLevel = t.TypeOf<typeof expressShipmentServiceLevelCodec>;
export type ApiCourierOrder = t.TypeOf<typeof courierOrderCodec>;
export type ApiParcel = t.TypeOf<typeof apiParcelCodec>;
export type ApiCourierOrderValidation = t.TypeOf<typeof courierOrderValidationsArrayCodec>;
