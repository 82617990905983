import NotFoundState from '@rio-cloud/rio-uikit/NotFoundState';
import { isEqual } from 'lodash';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { IdentityProvider, config } from '../../../../config';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/typedReduxHooks';
import {
    clearMarkingsOfDeliveryScheduleAndRefresh,
    fetchAndStoreDeliverySchedules,
    setSelectedDeliveryScheduleId,
} from '../../actions/deliverySchedules/DeliverySchedules.actions';
import { useDunsNumberFromPath } from '../../hooks/Routing.hooks';
import { deliverySchedulesSlice } from '../../reducers/deliverySchedules/DeliverySchedules.reducer';
import { DeliverySchedule, DeliveryScheduleWorkflow } from '../../reducers/deliverySchedules/types';
import {
    getDeliverySchedules,
    getDeliverySchedulesActiveFilters,
    getDeliverySchedulesQuery,
    getSelectedDeliveryScheduleId,
    getTotalCountOfMatchedDeliverySchedules,
    isLoadingDeliverySchedules,
} from '../../selectors/deliverySchedules/DeliverySchedules.selector';
import { ArticleNumber } from '../common/ArticleNumber';
import { LoadingIndicator } from '../common/LoadingIndicator';
import { Tooltip } from '../common/Tooltip';
import { FormattedDateOrDateTime } from '../common/i18n/FormattedDateOrDateTime';
import { FormattedDateTimeOrPeriod } from '../common/i18n/FormattedDateTimeOrPeriod';
import { DeliveryScheduleDialog } from './DeliveryScheduleDialog';
import { DeliveryScheduleFlags } from './DeliveryScheduleFlags';
import { DeliverySchedulesPaginationBar } from './DeliverySchedulesPaginationBar';
import { getTableHeadlinesWithTooltip, getTableHeadlinesWithoutTooltip } from './DeliverySchedulesTableHeadlines';
import { FormattedManufacturingCompany } from './FormattedManufacturingCompany';

const tableClassNames = [
    'table',
    'table-column-overflow-hidden',
    'table-bordered',
    'table-sticky',
    'table-head-filled',
    'text-center',
    'cursor-pointer',
].join(' ');

export const DeliverySchedulesTable = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const intl = useIntl();
    const dunsNumber = useDunsNumberFromPath();
    const query = useAppSelector(getDeliverySchedulesQuery);
    const activeFilters = useAppSelector(getDeliverySchedulesActiveFilters);
    const isVWProduction = config.identityProvider === IdentityProvider.VW;

    // biome-ignore lint/correctness/useExhaustiveDependencies: Migrated from eslint
    useEffect(() => {
        dispatch(deliverySchedulesSlice.actions.updateQuery({ ...query, dunsNumber }));
        dispatch(fetchAndStoreDeliverySchedules());
    }, [dunsNumber]);

    const isLoading = useAppSelector(isLoadingDeliverySchedules);
    const deliverySchedules = useAppSelector(getDeliverySchedules);
    const totalNumberOfDeliverySchedules = useAppSelector(getTotalCountOfMatchedDeliverySchedules);
    const selectedDeliveryScheduleId = useAppSelector(getSelectedDeliveryScheduleId);

    if (isLoading && selectedDeliveryScheduleId === undefined) {
        return <LoadingIndicator />;
    }

    const renderNotFoundState = () => {
        let messageId: string;
        const thereAreNoActiveFiltersSet =
            query.params.q === '' && Object.values(activeFilters).filter((value) => value !== undefined).length === 0;
        if (thereAreNoActiveFiltersSet) {
            messageId = 'webedi.deliverySchedule.nothingFoundMessage';
        } else {
            messageId = 'webedi.deliverySchedule.filtersNothingFoundMessage';
        }
        return (
            <NotFoundState
                headline={<FormattedMessage id={'webedi.nothingFound'} />}
                message={<FormattedMessage id={messageId} />}
            />
        );
    };

    if (deliverySchedules.length === 0) {
        return renderNotFoundState();
    }

    // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: Legacy code
    const renderRow = (deliverySchedule: DeliverySchedule) => {
        const isSelected = isEqual(selectedDeliveryScheduleId, deliverySchedule.id);
        const isCancelled = deliverySchedule.hasZeroDemand;
        const isSoftDeleted = deliverySchedule.isSoftDeleted;
        const hasInvalidMasterData = !isCancelled && !deliverySchedule.hasValidArticleMasterData;
        const hasInvalidCumulativeQuantitySent =
            !isCancelled &&
            !deliverySchedule.hasValidCumulativeQuantitySent &&
            deliverySchedule.workflow !== DeliveryScheduleWorkflow.DISCOVERY_NEXT_GEN;
        const hasWarning = hasInvalidMasterData || hasInvalidCumulativeQuantitySent;

        return (
            <tr
                className={`${isSelected ? 'active' : ''} ${isCancelled || isSoftDeleted ? 'bg-lightest text-color-gray' : ''} ${hasWarning ? 'warning' : ''}`}
                key={deliverySchedule.id}
                data-testid={DELIVERY_SCHEDULES_TABLE_ROW_TEST_ID}
                onClick={() => {
                    dispatch(deliverySchedulesSlice.actions.setSelectedDeliveryScheduleId(deliverySchedule.id));
                    dispatch(deliverySchedulesSlice.actions.setShowDeliveryScheduleDialog(true));
                    dispatch(clearMarkingsOfDeliveryScheduleAndRefresh(dunsNumber!, deliverySchedule.id, query.params));
                }}
            >
                <td className={'text-left'}>
                    {isSoftDeleted && renderDeletedTooltip()}
                    {isCancelled && renderZeroDemand()}
                    {hasInvalidCumulativeQuantitySent && renderInvalidCumulativeQuantitySent()}
                    {deliverySchedule.manuallyCreated && renderManuallyCreatedIcon()}
                </td>
                <td className={'text-left min-width-100 width-5pct'}>{renderFlags(deliverySchedule)}</td>
                <td>
                    <ArticleNumber
                        articleNumber={
                            deliverySchedule.scheduledArticleDetails.lineItem.lineItemIdentifier
                                .itemNumberIdentification.itemIdentifier
                        }
                    />
                </td>
                <td>
                    <ArticleNumber articleNumber={deliverySchedule.articleMasterData?.articleNumberSeller} />
                </td>
                <td>{deliverySchedule.shipFrom.partyIdentificationDetails.identifier}</td>
                <td>{deliverySchedule.shipTo.shipTo.partyIdentificationDetails.identifier}</td>
                <td>{deliverySchedule.shipTo.placeOfDischarge.locationNameCode}</td>
                <td>{deliverySchedule.shipTo.placeOfDelivery.locationNameCode}</td>
                <td>{deliverySchedule.scheduledArticleDetails.orderNumber.referenceIdentifier}</td>
                <td>
                    <FormattedManufacturingCompany manufacturingCompany={deliverySchedule.buyer} />
                </td>
                <td>
                    <FormattedDateTimeOrPeriod
                        datetimeOrPeriod={deliverySchedule.scheduledArticleDetails.schedulingData[0].scheduledDateTime}
                    />
                </td>
                <td>
                    {deliverySchedule.latestDeliveryCallOffDate && (
                        <FormattedDateOrDateTime date={deliverySchedule.latestDeliveryCallOffDate} />
                    )}
                </td>
                <td className={'text-right'}>
                    <button
                        className={`btn ${hasInvalidMasterData ? 'btn-warning' : 'btn-default'} btn-outline padding-5`}
                        onClick={(event) => {
                            dispatch(setSelectedDeliveryScheduleId(dunsNumber!, deliverySchedule.id, navigate));
                            event.stopPropagation();
                        }}
                    >
                        <span
                            className={`icon rioglyph ${hasInvalidMasterData ? 'rioglyph-exclamation-sign' : 'rioglyph-ok'}`}
                        />
                        <FormattedMessage id={'webedi.deliverySchedule.masterData.openButton'} />
                    </button>
                </td>
            </tr>
        );
    };

    const renderDeletedTooltip = () => (
        <Tooltip text={intl.formatMessage({ id: 'webedi.deliverySchedule.isSoftDeleted.label' })} placement={'bottom'}>
            <span className={'rioglyph rioglyph-trash text-size-18 margin-right-5'} />
        </Tooltip>
    );

    const renderZeroDemand = () => (
        <Tooltip text={intl.formatMessage({ id: 'webedi.deliverySchedule.zeroDemand.label' })} placement={'bottom'}>
            <span className={'rioglyph rioglyph-ban-circle text-size-18 margin-right-5'} />
        </Tooltip>
    );

    const renderFlags = (deliverySchedule: DeliverySchedule) =>
        !deliverySchedule.hasZeroDemand ? <DeliveryScheduleFlags flags={deliverySchedule.flags} /> : undefined;

    const renderInvalidCumulativeQuantitySent = () => (
        <Tooltip
            text={intl.formatMessage({ id: 'webedi.deliverySchedule.invalidCumulativeQuantity.label' })}
            placement={'bottom'}
        >
            <span className={'rioglyph rioglyph-exclamation-sign text-size-18 text-warning margin-right-5'} />
        </Tooltip>
    );

    const renderManuallyCreatedIcon = () => (
        <Tooltip
            text={intl.formatMessage({ id: 'webedi.deliverySchedule.manuallyCreated.label' })}
            placement={'bottom'}
        >
            <span className={'rioglyph rioglyph-user-sign text-size-18 text-color-dark'} />
        </Tooltip>
    );

    const renderTableHeader = () => (
        <thead>
            <tr data-testid={DELIVERY_SCHEDULES_TABLE_HEADER_TEST_ID}>
                {isVWProduction ? getTableHeadlinesWithoutTooltip() : getTableHeadlinesWithTooltip()}
            </tr>
        </thead>
    );

    return (
        <div>
            <div className={'table-responsive margin-bottom-20'}>
                <table className={tableClassNames} data-testid={DELIVERY_SCHEDULES_TABLE_TEST_ID}>
                    {renderTableHeader()}
                    <tbody>{deliverySchedules.map(renderRow)}</tbody>
                </table>
                <DeliveryScheduleDialog />
            </div>
            <div className={'display-flex flex-row align-items-center justify-content-center'}>
                <DeliverySchedulesPaginationBar
                    totalNumberDeliverySchedules={totalNumberOfDeliverySchedules}
                    limit={query.params.limit}
                    offset={query.params.offset}
                />
            </div>
        </div>
    );
};

export const DELIVERY_SCHEDULES_TABLE_TEST_ID = 'DELIVERY_SCHEDULES_TABLE_TEST_ID';
export const DELIVERY_SCHEDULES_TABLE_ROW_TEST_ID = 'DELIVERY_SCHEDULES_TABLE_ROW_TEST_ID';
export const DELIVERY_SCHEDULES_TABLE_HEADER_TEST_ID = 'DELIVERY_SCHEDULES_TABLE_HEADER_TEST_ID';
