import Button from '@rio-cloud/rio-uikit/Button';
import { useNavigate } from 'react-router';
import { useLoggedInUserDunsNumber } from '../../../hooks/Routing.hooks';
import { Routing } from '../../routing/Routes';

export const RioSelectedDunsNumber = () => {
    const dunsNumber = useLoggedInUserDunsNumber();
    const navigate = useNavigate();
    return (
        dunsNumber && (
            <div>
                <Button
                    className={'padding-left-0'}
                    variant={Button.VARIANT_LINK}
                    onClick={() => navigate(Routing.dunsSelection)}
                >
                    {dunsNumber}
                    <span className={'rioglyph rioglyph-pencil margin-left-10 margin-bottom-3'} />
                </Button>
            </div>
        )
    );
};
